.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
    gap: 40px;
    color: #fff;
    font-size: 1rem;
    font-family: "Monserrat-bold";
    grid-row: 2;
    img {
        width: 50%;
        animation-name: rotate;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-direction: normal;
    }
}
