.shopping-bag {
    background-color: #0000006e;
    backdrop-filter: blur(75px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 40px 30px 40px;
    display: flex;
    justify-content: center;
    .container {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow-y: auto;
        height: 100%;
        padding-inline-end: 20px;

        width: 60vw;;

        &::-webkit-scrollbar {
            width: 0.3em;
            height: 0.1em;
        }
        &::-webkit-scrollbar-button {
            background: transparent;
        }
        &::-webkit-scrollbar-track-piece {
            background: #262626;
        }
        &::-webkit-scrollbar-thumb {
            background: rgb(71, 71, 71);
        }
        .close {
            position: absolute;
            top: 5px;
            right: 50px;
            height: 17px;
            width: 17px;
            cursor: pointer;
        }
        .title {
            color: white;
            font-size: 23px;
            font-weight: bold;
            text-align: center;
            margin: 0px;
            font-family: "Monserrat-bold";
        }

        

        .btn {
            all: unset;
            margin: 0 auto;
            width: 218px;
            min-height: 46px;
            border-radius: 23.5px;
            background-color: $mainClr;
            color: #fff;
            font-family: "Monserrat-bold";
            font-size: 15px;
            text-align: center;
            cursor: pointer;
        }
    }
}
