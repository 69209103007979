.wrapper {
    position: relative;
    // margin: 40px auto;
    background: #ddd;
    width: 25px;
    height: 25px;

    .pie {
        width: 50%;
        height: 100%;
        transform-origin: 100% 50%;
        position: absolute;
        background: $mainClr;
        border: 2px solid $mainClr;
    }

    .spinner {
        border-radius: 100% 0 0 100% / 50% 0 0 50%;
        z-index: 200;
        border-right: none;
        animation: rota calc(var(--duration) * 1ms) linear infinite;
    }

    .filler {
        border-radius: 0 100% 100% 0 / 0 50% 50% 0;
        left: 50%;
        opacity: 0;
        z-index: 100;
        animation: opa calc(var(--duration) * 1ms) steps(1, end) infinite reverse;
        border-left: none;
    }

    .mask {
        width: 50%;
        height: 100%;
        position: absolute;
        background: inherit;
        opacity: 1;
        z-index: 300;
        animation: opa calc(var(--duration) * 1ms) steps(1, end) infinite;
    }
}

@keyframes rota {
    100% {
        transform: rotate(0deg);
    }
    0% {
        transform: rotate(360deg);
    }
}

@keyframes opa {
    0% {
        opacity: 0;
    }
    50%,
    100% {
        opacity: 1;
    }
}
