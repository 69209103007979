html {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: "Monserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    &:not(input) {
        -webkit-user-select: none;
        user-select: none;
    }
}

button {
    cursor: pointer;
}

.screen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #0000006e;
    backdrop-filter: blur(75px);
    z-index: -1;
}

.jBox-Notice.jBox-Notice-blue .jBox-container {
    background-color: #fff;
    color: #000;
    font-size: 16px;
    .jBox-content {
        &::before {
            content: url(../img/greenV.svg);
            height: 25px;
            width: 25px;
            margin-inline-end: 12px;
        }
    }
}

.jBox-content {
    text-align: center;
    display: flex;
    justify-content: center;
}

.shimmer-loader {
    background-color: rgba(211, 211, 211, 0.4);
    mask: linear-gradient(-60deg, #000 30%, #fff9, #000 70%) right/500% 100%;
    background-repeat: no-repeat;
    animation: shimmer 1.0s infinite;
}

@keyframes shimmer {
    100% {
        mask-position: left;
    }
}

@font-face {
    font-family: "Monserrat";
    src: url("../fonts/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
    font-family: "Monserrat-semi-bold";
    src: url("../fonts/Montserrat/Montserrat-SemiBold.ttf");
}

@font-face {
    font-family: "Monserrat-bold";
    src: url("../fonts/Montserrat/Montserrat-Bold.ttf");
}

@font-face {
    font-family: "Monserrat-medium";
    src: url("../fonts/Montserrat/Montserrat-Medium.ttf");
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
