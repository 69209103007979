.form2 {
    overflow-y: auto;
    padding-inline: 20px;
    padding-block-start: calc(65px + clamp(20px,7vw,65px));
    // padding-block-end: 80px;
    // padding-block-start: 140px;
    align-self: flex-end;
    &::-webkit-scrollbar {
        width: 0.3em;
        height: 0.1em;
    }
    &::-webkit-scrollbar-button {
        background: transparent;
    }
    &::-webkit-scrollbar-track-piece {
        background: #262626;
    }
    &::-webkit-scrollbar-thumb {
        background: rgb(71, 71, 71);
    }

    .flex-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input:focus-visible {
        outline: 1px solid #fff;
    }

    input {
        color: #fff;
        padding: 18px 30px;
        font-size: 20px;
    }

    .header {
        position: absolute;
        top: 57px;
        left: 8%;

        img {
            width: clamp(20px, 7vw, 65px);
            height: clamp(20px, 7vw, 65px);
        }
    }

    .back {
        cursor: pointer;
        width: max-content;
        padding: 10px 10px 10px 0;
        .back-arrow{
            height: clamp(10px, 5vw, 32px);
        }
    }

    form {
        display: flex;
        flex-direction: column;
        .form-title {
            color: #ffffff;
            font-size: 23px;
            $min_width: 200px;
            $max_width: 900px;
            $min_font: 12px;
            $max_font: 23px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
            line-height: 73px;
            margin-block-start: 0px;
            margin-block-end: 33px;
            margin-inline-start: 26px;
            @include media(phone){
                margin-block-end: 10px;
            }
        }
        .bra-size-container {
            display: flex;
            gap: 20px;
            .bra-size.button {
                width: 280px;
                height: 60px;
                border-radius: 30px;
                border: 1px solid;
                font-size: 20px;
                $min_width: 200px;
                $max_width: 900px;
                $min_font: 12px;
                $max_font: 20px;
                @include fluid-type($min_width, $max_width, $min_font, $max_font);
                cursor: pointer;
                color: #8d8d8d;
                border-color: #8d8d8d;
                background-color: #262626;
                transition: 200ms;
                label {
                    position: relative;
                    cursor: pointer;
                }
                img {
                    position: absolute;
                    transform: translate(calc(-100% - 7.8px), 50%);
                    margin-inline-end: 7.6px;
                    display: none;
                    cursor: pointer;
                    height: 50%;
                }
                &.active {
                    color: $mainClr;
                    border-color: $mainClr;
                    img {
                        display: inline-block;
                    }
                }
            }
        }
        .input-container {
            display: flex;
            flex-direction: column;
            gap: 13px;
            margin-block-start: 41px;
            position: relative;
            @include media(phone){
                margin-block: 30px;
            }
            .row1 {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                label {
                    color: #ffffff;
                    font-size: 20px;
                    $min_width: 200px;
                    $max_width: 900px;
                    $min_font: 12px;
                    $max_font: 20px;
                    @include fluid-type($min_width, $max_width, $min_font, $max_font);
                    margin-inline-start: 30px;
                }
                .switch {
                    margin-inline-end: 32px;
                    button {
                        background-color: #262626;
                        border: 1px solid #5a5a5a;
                        width: 49px;
                        height: 30px;
                        color: #8d8d8d;
                        font-size: 16px;
                        $min_width: 200px;
                        $max_width: 900px;
                        $min_font: 10px;
                        $max_font: 16px;
                        @include fluid-type($min_width, $max_width, $min_font, $max_font);
                        max-width: 50%;
                        &.right {
                            border-radius: 0 15px 15px 0;
                        }
                        &.left {
                            border-radius: 15px 0 0 15px;
                        }
                        &.active {
                            border: 0;
                            background-color: $mainClr;
                            color: #ffffff;
                        }
                    }
                }
            }
            .row2 {
                display: flex;
                gap: 20px;
                .dropdown {
                    width: 280px;
                    height: 60px;
                    border-radius: 30px;
                    background-color: #262626;
                    border: 1px solid #5a5a5a;
                    position: relative;
                    *:focus-visible {
                        outline: none !important;
                        box-shadow: none;
                    }
                    .select {
                        background-color: transparent;
                        border: none;
                        color: #fff;
                        font-size: 20px;
                        $min_width: 200px;
                        $max_width: 900px;
                        $min_font: 12px;
                        $max_font: 20px;
                        @include fluid-type($min_width, $max_width, $min_font, $max_font);
                        width: 100%;
                        & > div:first-of-type {
                            background-color: transparent;
                            border: none;
                            box-shadow: none;
                            & > div:first-of-type {
                                & > div:first-of-type {
                                    // each text in drop down
                                    color: #fff;
                                    text-align: center;
                                }
                            }
                            & > div:nth-child(2) {
                                span {
                                    display: none;
                                }
                                div {
                                    padding-inline-end: 36px;
                                    svg {
                                        fill: #8d8d8d;
                                    }
                                }
                            }
                        }
                        & > div:nth-of-type(2) {
                            // drop down options box
                            background-color: #262626;
                        }
                    }
                    img {
                        position: absolute;
                        right: 36px;
                    }
                }
            }
        }
        .button.next {
            margin-block-start: 69px;
            background-color: $mainClr;
            color: #fff;
            font-size: 22px;
            font-weight: bold;
            border-radius: 30px;
            height: 60px;
            border: 0;
            @include media(phone){
                margin-block: 30px;
            }
        }
    }
}
