// @import "~slick-carousel/slick/slick.css";
// @import "~slick-carousel/slick/slick-theme.css";

.color-cmp {
    // display: flex;
    // overflow-x: auto;
    // overflow-y: hidden;
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
    width: 308px;
    height: 100px;
    margin: auto;
    transform: translateX(0px);
    // &::-webkit-scrollbar {
    //     display: none;
    // }


}

