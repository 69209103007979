.error-msg {
    position: absolute;
    display: grid;
    place-content: center;
    width: 100%;
    height: calc(100vh - 50vmin);
    grid-row: 2;
    .screen {
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        background-color: #000;
        opacity: 0.8;
    }
    .content {
        z-index: 11;
        background-color: #ddd;
        padding: 40px;
        margin: 0;
        border-radius: 15px;
        text-align: center;
        line-height: 2rem;
        font-family: "Monserrat-bold";
        span {
            font-size: 1.8rem;
        }
        button {
            border-radius: 20px;
            width: 100%;
            height: 2rem;
            // margin-top: 30px;
            background-color: $mainClr;
            border: none;
            color: #fff;
            font-family: "Monserrat-bold";
            transition: 0.2s;
            &:hover {
                transition: 0.2s;
                background-color: darken($color: $mainClr, $amount: 5%);
            }
        }
        .pie-timer{
            display: flex;
            justify-content: flex-end;
            position: relative;
            top: -25px;
            right: -25px;
        }
    }
    &.error {
        color: red;
    }
    &.success {
        color: green;
    }
    &.normal {
        color: #000;
    }

    
}


