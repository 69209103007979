.form1 {
    overflow-y: auto;
    padding-inline: 20px;
    // padding-block-end: 80px;
    &::-webkit-scrollbar {
        width: 0.3em;
        height: 0.1em;
    }
    &::-webkit-scrollbar-button {
        background: transparent;
    }
    &::-webkit-scrollbar-track-piece {
        background: #262626;
    }
    &::-webkit-scrollbar-thumb {
        background: rgb(71, 71, 71);
    }

    .flex-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input:focus-visible {
        outline: 1px solid #fff;
    }

    input {
        color: #fff;
        padding: 18px 30px;
        font-size: 20px;
    }

    .header {
        img {
            width: 228px;
            height: 52px;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        .form-title {
            color: #ffffff;
            font-size: 53px;
            $min_width: 200px;
            $max_width: 900px;
            $min_font: 12px;
            $max_font: 53px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
            text-align: center;
            line-height: 70px;
            line-height: calc(1.2 + (68.8 * (2 / #{strip-unit($max-width - $min-width)})));
            margin-block-start: 37px;
            margin-block-end: 65px;
            @include media(phone){
                margin-block: 20px;
            }
        }
        .gender-container {
            display: flex;
            justify-content: space-between;
            .gender.button {
                // width: 280px;
                width: 45%;
                height: 60px;
                border-radius: 30px;
                border: 1px solid;
                font-size: 20px;
                cursor: pointer;
                color: #8d8d8d;
                background-color: #262626;
                border-color: #8d8d8d;
                transition: 200ms;
                label {
                    position: relative;
                    cursor: pointer;
                    $min_width: 200px;
                    $max_width: 900px;
                    $min_font: 12px;
                    $max_font: 20px;
                    @include fluid-type($min_width, $max_width, $min_font, $max_font);
                }
                img {
                    position: absolute;
                    transform: translate(calc(-100% - 7.8px), 50%);
                    height: 50%;
                    margin-inline-end: 7.6px;
                    display: none;
                    cursor: pointer;
                }
                &.active {
                    color: $mainClr;
                    border-color: $mainClr;
                    img {
                        display: inline-block;
                    }
                }
            }
        }
        .input-container {
            display: flex;
            flex-direction: column;
            gap: 13px;
            margin-block-start: 41px;
            position: relative;
            @include media(phone){
                margin-block-start: 20px;
            }
            .row1 {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                label {
                    color: #ffffff;
                    font-size: 20px;
                    $min_width: 200px;
                    $max_width: 900px;
                    $min_font: 12px;
                    $max_font: 20px;
                    @include fluid-type($min_width, $max_width, $min_font, $max_font);
                    margin-inline-start: 30px;
                }
                .switch {
                    margin-inline-end: 32px;
                    button {
                        background-color: #262626;
                        border: 1px solid #5a5a5a;
                        width: 49px;
                        height: 30px;
                        color: #8d8d8d;
                        font-size: 16px;
                        $min_width: 200px;
                        $max_width: 900px;
                        $min_font: 12px;
                        $max_font: 16px;
                        @include fluid-type($min_width, $max_width, $min_font, $max_font);
                        max-width: 50%;
                        &.right {
                            border-radius: 0 15px 15px 0;
                        }
                        &.left {
                            border-radius: 15px 0 0 15px;
                        }
                        &.active {
                            border: 0;
                            background-color: $mainClr;
                            color: #ffffff;
                        }
                    }
                }
            }
            .row2 {
                display: flex;
                border-radius: 30px;
                &.focus {
                    outline: 1px solid #fff;
                }
                &.invalid {
                    outline: 1px solid #ff000b;
                    input {
                        outline: none;
                    }
                }
                div {
                    display: flex;
                    width: 100%;
                    position: relative;
                    input {
                        border: 1px solid #5a5a5a;
                        background-color: #262626;
                        border-radius: 30px;
                        width: 100%;
                        height: 60px;
                        padding-inline-end: 60px;
                        &.ft:focus-visible,
                        &.in:focus-visible,
                        &.cm:focus-visible {
                            outline: none;
                        }
                        &.ft {
                            border-start-end-radius: 0;
                            border-end-end-radius: 0;
                            // width: 50%;
                        }
                        &.in {
                            border-start-start-radius: 0;
                            border-end-start-radius: 0;
                            // width: 50%;
                        }
                    }
                    .input-label {
                        position: absolute;
                        transform: translateY(-50%);
                        right: 0;
                        top: 50%;
                        color: #8d8d8d;
                        font-size: 16px;
                        padding-inline-end: 27px;
                    }
                }
            }
            .row3 {
                position: absolute;
                bottom: 0;
                transform: translate(30px, calc(100% + 4px));
                transition: 100ms;
                &.hide {
                    opacity: 0;
                }
                &.show {
                    opacity: 1;
                }
                .error {
                    color: #ff000b;
                    font-size: 15px;
                }
            }
        }
        .button.next {
            margin-block-start: 69px;
            background-color: $mainClr;
            color: #fff;
            font-size: 22px;
            font-weight: bold;
            border-radius: 30px;
            height: 60px;
            border: 0;
            @include media(phone){
                margin-block: 30px;
            }
        }
        .footer {
            margin-block-start: 43px;
            font-size: 16px;
            color: #fff;
            text-align: center;
            a {
                color: inherit;
            }
        }
    }
}
