.install-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    // padding-block-end: 50px;
    // padding-inline-end: 50px;
    overflow: auto;

    form{
        button{
            color: white;
            border: 0;
            &:disabled{
                color: rgba(255, 255, 255, 0.5);

            }
        }
    }

    &::-webkit-scrollbar {
        width: 0.3em;
        height: 0.1em;
    }
    &::-webkit-scrollbar-button {
        background: transparent;
    }
    &::-webkit-scrollbar-track-piece {
        background: #262626;
    }
    &::-webkit-scrollbar-thumb {
        background: rgb(71, 71, 71);
    }

    @media(max-height: 850px){
        justify-content: flex-start;
    }
    .logo {
        height: 55px;
        width: 273.6px;
        margin: 0 auto 10px;
    }
    h1 {
        font-size: 41px;
        font-weight: bold;
        line-height: 1.51;
        text-align: center;
        margin-block-end: 49.6px;
        .ltd {
            font-size: 15px;
            text-transform: uppercase;
            font-weight: bold;
        }
    }
    form {
        .margin-top-25 {
            margin-block-start: 25px;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        .form-input {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // max-width: 580px;
            width: 100%;
            gap: 12px;
            label {
                font-size: 20px;
                margin-inline-start: 20px;
            }
            input {
                // max-width: 580px;
                width: 100%;
                height: 60px;
                border-radius: 30px;
                border: 1px solid #5a5a5a;
                background-color: #262626;
                color: #fff;
                font-size: 20px;
                padding: 0 20px;
                &:focus-visible {
                    outline: none;
                }
            }
        }
        button {
            margin: 53px 0 0;
            // padding: 18px 0;
            padding: 0;
            border-radius: 30px;
            // max-width: 580px;
            width: 100%;
            height: 60px;
            background-color: $mainClr;
            font-size: 22px;
            font-weight: bold;

            &:disabled {
                * {
                    cursor: not-allowed;
                }
            }

            .ver-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px;

                img,
                svg {
                    zoom: 0.18;
                    animation-name: rotate;
                    animation-duration: 1s;
                    animation-timing-function: linear;
                    animation-iteration-count: infinite;
                    animation-direction: normal;
                }
            }
        }

        .form-validation-message {
            margin-block-start: 10px;
            opacity: 0;
            transition: 0.2s;
            &.show {
                opacity: 1;
            }
        }
    }

    @include media(phone-portrait) {
        padding-inline-end: unset;
        .logo{
            height: 35px;
            margin-block-end: 30px;
        }
        h1{
            font-size:15px;
            margin-block-start: 0px;
            margin-block-end: 30px;
            .ltd{
                font-size:7px;
            }
        }
        form{
            .form-input{
                label{
                    font-size:12px;
                }
                input{
                    height:40px;
                    width: 100%;
                    margin: 0 auto 0;
                }
            }
            button{
                height:40px;
                width: 100%;
                margin: 40px 0 0 0;
                font-size: 15px;
            }
        }
    }
}
