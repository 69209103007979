.step1 {
    display: flex;
    justify-content: center;
    height: max-content;
    max-width: 80%;
    font-family: "Monserrat-bold";


    .main {
        align-items: center;
    }




    .img {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
        img {
            margin-right: 20px;
            width: 35vmin;
        }
    }
    .main {
        // flex-grow: 1;
        // padding: 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        // & > img {
        //     width: 20vmin;
        //     margin: -2vmin auto 0;
        // }
        h1,
        h2 {
            margin: 10px;
        }
        h1 {
            color: #fff;
            font-size: 4vmin;
            line-height: 5vmin;
            font-family: "Monserrat-bold";
        }
        .text {
            margin: 30px 0;
            text-align: center;
            h2 {
                color: #f8686e;
                font-size: 1.2rem;
                line-height: 1.8rem;
                font-family: "Monserrat-semi-bold";
            }
        }
        .animation.footer {
            margin: 0 auto;
            display: flex;
            align-items: flex-start;
            gap: 0.5rem;

            img[alt="Scanner"] {
                width: 8vmin;
                animation-name: scanner;
                animation-duration: 0.9s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                animation-direction: alternate;
            }
            .qr-code {
                position: relative;
                flex-grow: 1;
                display: flex;
                img[alt="QR Code"] {
                    // width: 50px;
                    width: 5vmin;
                }
                span {
                    width: 100%;
                    position: absolute;
                    display: block;
                    animation-name: beam-laser;
                    animation-duration: 0.9s;
                    animation-timing-function: ease-in-out;
                    animation-iteration-count: infinite;
                    animation-direction: alternate;
                    &::before {
                        content: "";
                        width: calc(100% + 5px);
                        // width: calc(calc(4.5vw + 5px));
                        border: 2px solid $mainClr;
                        position: absolute;
                        top: -5px;
                        left: -5px;
                    }
                }
            }
            div {
                flex-grow: 1;
            }
        }
    }

        

    &__next-button {
        background-color: #f8686e;
        height: 6vmin;
        width: 40vmin;
        text-align: center;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 1000px;
        margin-top: 40px;
        // width: 80%;
        font-size: 1.7vmin;

        img {
            transform: rotateY(180deg);
            margin-left: 10px;
            height: 15px;
        }
    }
        &__back-arrow {
            height: 45px;
            width: unset;
            position: absolute;
            left: 80px;
            cursor: pointer;
        }

        .form-qr {

            .text {
                margin: 10px 0;
            }
            .form-first-text  {
                font-size: 5vmin;
            }
            .form-second-text  {
                font-size: 2vmin;
            }
        

            &__logo {
                height: 3vh;
            }

            .img {
                display: none;
            }

            .step1__form-qr-code {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 15vmin; 
                }
            }
        }
    
}

@keyframes scanner {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(20deg);
    }
}

@keyframes beam-laser {
    from {
        top: 5px;
    }
    to {
        top: 104%;
    }
}

@media (max-width: 600px) {
    .step1 {
        flex-direction: column;
        position: fixed;
        top: 50%;
        transform: translateY(-50%);


        .mysz--logo {
            height: 10vw;
        }

        .form-qr{

            .form-qr__logo {
                width: 50vw;
                height: unset;
            }
            
            .step1__form-qr-code{
                img {
                    width: 30vmin;
                }
            }
            .girl-image {
                display: block;
                margin-top: 20px;
                
                img {
                    margin: 0 20px;
                    display: block;
                    height: unset;
                    width: 70vw;
                }
            }
            .form-first-text{
                font-size: 6vmin;
                line-height: 8vmin;

            }
            .form-second-text{
                font-size: 3vmin;
            }
         


        }
        &__next-button {
            width: 80vw;
            height: 5vh;
            font-size: 3.5vmin;
        }
        

        .main {
            padding: 0;
            h1 {
                font-size: 7vmin;
                line-height: 1;
            }
            img {
                // width: 40vmin;
            }

            .animation.footer img[alt="Scanner"] {
                width: 15vmin;
            }
            .animation.footer .qr-code img[alt="QR Code"]  {
                width: 9vmin;
            }
        }
        .img {
            img {
                height: 30vh;
                margin-right: 0;
                display: none;
            }
        }

        
        .form-profile-scan {
            .step1__back-arrow {
                height: 10vw;
                width: unset;
                position: absolute;
                left: 5vw;
                cursor: pointer;
            }
            .logo {
                width: 30vw;
            }
        }
    }
}

@include media(phone-landscape){
    .step1{
        max-height: 70vh;
        align-self: center;
        .img{
            width: 50%;
            display: flex;
            justify-content: flex-end;
            display: none;
            img{
                height: 100%;
                justify-self: flex-end;
            }
        }
        .main{
            width: 50%;
        }

        
    }
}

@include media(tablet-landscape){
    .step1{
        align-self: center;
    }
}
