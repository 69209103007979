.bringme-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(75px);
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    padding-block-start: 150px;
    
    &.show{
        visibility: visible;
        opacity: 1;
        // font-size: 10rem;
    }

    img {
        width: 20%;
    }

    h1 {
        font-size: 2.5rem;
        margin-block-end: 0.1em;
    }

    h2 {
        font-size: 1.5rem;
        text-align: center;
    }
}
