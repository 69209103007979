.circular-progress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    position: relative;

    .precent {
        position: relative;
        width: 150px;
        height: 150px;

        svg {
            position: relative;
            width: 150px;
            height: 150px;
            circle {
                width: 150px;
                height: 150px;
                fill: none;
                stroke-width: 10;
                stroke: white;
                transform: translate(5px, 5px);
                stroke-dasharray: 440;
                stroke-dashoffset: 440;
                stroke-linecap: round;

                &:nth-child(1) {
                    stroke-dashoffset: 0;
                    stroke: #d1d0d0;
                }
                &:nth-child(2) {
                    stroke-dashoffset: calc(440 - (440 * var(--precent) / 100));
                    stroke: $mainClr;
                }
            }
        }

        .number {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #999;
            
            h2 {
                font-size: 48px;
                &:before {
                    content: attr(abbr);
                    position: relative;
                    top: 0;
                    left: 0;
                }

                span {
                    font-size: 24px;
                }
            }
        }
    }
}
