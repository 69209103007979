@import './style/variables';
@import './style/basic';
@import './style/mixins';
@import './style/views/app';
@import './style/cmps/loaderCmp';
@import './style/cmps/errorMsg';
@import './style/cmps/pieTimer';
@import './style/cmps/nav';
@import './style/cmps/installScreen';
@import './style/cmps/step1';
@import './style/cmps/step2';
@import './style/cmps/step3';
@import './style/cmps/product';
@import './style/cmps/avatar';
@import './style/cmps/circularProgress';
@import './style/cmps/bringMeMsg';
@import './style/cmps/navMsg';
@import './style/cmps/carouselCmp';
@import './style/cmps/carousel2Cmp';
@import './style/cmps/colorCmp';
@import './style/cmps/form1';
@import './style/cmps/form2';
@import './style/cmps/form3';
@import './style/cmps/checkOut/shoppingBag';
@import './style/cmps/checkOut/itemList';
@import './style/cmps/checkOut/itemCmp';
@import './style/cmps/checkOut/shoppingMsg';
@import './style/cmps/checkOut/checkOut';
@import './style/cmps/checkOut/sliderMsg';

.version{
    position: fixed;
    bottom: 0;
    left: 0;
    color: #eee;
    font-size: 12px;
    background-color: transparent;
}