.form3 {
    overflow-y: auto;
    padding-inline: 20px;
    // padding-block-end: 80px;
    // padding-block-start: 140px;
    padding-block-start: calc(65px + clamp(20px,7vw,65px));
    max-width: 625px;
    width: 100%;
    &::-webkit-scrollbar {
        width: 0.3em;
        height: 0.1em;
    }
    &::-webkit-scrollbar-button {
        background: transparent;
    }
    &::-webkit-scrollbar-track-piece {
        background: #262626;
    }
    &::-webkit-scrollbar-thumb {
        background: rgb(71, 71, 71);
    }

    .flex-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header {
        position: absolute;
        top: 57px;
        left: 8%;

        img {
            width: clamp(20px, 7vw, 65px);
            height: clamp(20px, 7vw, 65px);
        }
    }

    .back {
        cursor: pointer;
        width: max-content;
        padding: 10px 10px 10px 0;
        .back-arrow{
            height: clamp(10px, 5vw, 32px);
        }
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 35px;
        @include media(phone){
            gap: 10px;
        }
        .container {
            display: flex;
            flex-direction: column;
            .form-title {
                color: #ffffff;
                font-size: 23px;
                $min_width: 200px;
                $max_width: 900px;
                $min_font: 12px;
                $max_font: 23px;
                @include fluid-type($min_width, $max_width, $min_font, $max_font);
                line-height: 73px;
                margin-block-start: 0px;
                margin-block-end: 33px;
                margin-inline-start: 26px;
                @include media(phone){
                    margin-block-end: 0px;
                }
            }
            .options {
                display: flex;
                justify-content: space-evenly;
                gap: 5px;
                margin-inline: 4vw;
                div {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    gap: 10px;
                    cursor: pointer;
                    img {
                        width: clamp(50px, 20vw, 110px);
                        // height: 143px;
                        border-radius: 5px;
                        border: 5px solid #000;
                    }
                    label {
                        font-size: 16px;
                        color: #fff;
                        text-align: center;
                        cursor: pointer;
                    }
                    &.active {
                        img {
                            outline: 2px solid $mainClr;
                        }
                        label {
                            color: $mainClr;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
        .button.next {
            margin-block-start: 7vw;
            background-color: $mainClr;
            color: #fff;
            font-size: 22px;
            font-weight: bold;
            border-radius: 30px;
            height: 60px;
            border: 0;
        }
    }
}
