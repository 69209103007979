.shopping-bag-item {
    display: flex;
    position: relative;
    padding-inline-start: 50px;
    padding-inline-end: 20px;
    .item-image {
        height: 78px;
        width: 78px;
        min-height: 78px;
        min-width: 78px;
        border-radius: 50%;
        object-fit: cover;
    }
    .item-image-remove {
        position: absolute;
        top: -12px;
        left: 40px;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        object-fit: contain;
        cursor: pointer;
    }
    .item-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-inline-start: 24.5px;
        width: max-content;
        label {
            color: white;
            font-weight: bolder;
            font-size: 16px;
            margin-block-end: 9px;
            font-family: "Monserrat-medium";
            font-weight: 500;
            &.item-name,
            &.item-variants {
                // white-space: pre;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
            &.item-price {
            //     font-family: "Monserrat-semi-bold";
            //     font-weight: 600;
            margin-block-end: 0;
            }
        }

        .item-size, .item-qty {
            color: white;
            font-family: "Monserrat-medium";
        }
    }
}
hr {
    width: 100%;
    border: 0;
    border-top: 0.5px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        #e6e6e6 5%,
        #e6e6e6 95%,
        rgba(255, 255, 255, 0) 100%
    );
    margin-top: 26px;
    margin-bottom: 30px;
}