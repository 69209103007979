.avatar-wrapper {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index:200;
    backdrop-filter: blur(10px);
    left:0;
    top:0;
    // align-items: center;
    justify-content: center;
    display: flex;
    padding: 5vh;
}


.avatar-container {
    background-color: #e3e3e3;
    width: 800px;
    max-width: 95%;
    max-height: 650px;
    min-height: 650px;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
    padding: 65px 20px;
    position: relative;
    overflow: hidden;
    // margin: 0 20px;
    // margin-top: 35px;

    // position: absolute;
    // top: 20px;
    // left: 50%;
    z-index: 10;
    // transform: translateX(-50%);

    .no-image{
        margin: auto;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 4vw;
    }
    .close-overlay {
        position: absolute;
        top: 0;
        right: 0;
        width: 10%;
        height: 10%;
        cursor: pointer;
        z-index: 999;
    }

    &__close {
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
        height: 20px;
        width: 20px;
    }

    .panel {
        display: flex;
        flex-direction: column;
        align-items: center;
        // height: 100%;
        // width: 100%;
        &__size {
            font-size: 30px;
            font-family: Monserrat-bold;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 400px;
            margin-top: 15px;
            color: black;
        }
        select {
            width: 200px;
            background-color: white;
            border-radius: 20px;
            height: 40px;
            text-align: center;
            cursor: pointer;

            outline: none;
            // &:active, &:focus {
            //     outline:none;
            // }
        }
        .avatar {
            width: 100%;
            height: calc(100% - 80px);
            flex-grow: 1;
            & > div {
                height: 100%;
                width: 100%;
                .spinner {
                    // width: fit-content;
                    transition: 0.3s;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 2;
                    &.show {
                        opacity: 1;
                    }
                    &.hide {
                        opacity: 0;
                        animation: hidespinner 0.01s;
                        animation-delay: 0.3s;
                        animation-fill-mode: forwards;
                        @keyframes hidespinner {
                            to {
                                left: -100%;
                            }
                        }
                    }
                }
                .container-fluid {
                    height: 100%;
                    width: 100%;
                    .threesixty {
                        height: 100%;
                        width: 100%;
                        div:last-child {
                            height: 100%;
                            width: 100%;
                            img {
                                // height: var(--size);
                                // width: var(--size);
                                height: 100%;
                                width: 70%;
                                object-fit: contain;
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media(tablet-portrait) {
}

@include media(tablet-landscape) {
    .avatar-container {
        margin-top: 10px;
        padding: 40px 0;
        .panel {
            &__size {
                margin-top: 0;
            }
        }
    }
}

@include media(phone-portrait) {
}

@include media(phone-landscape) {
    .avatar-container {
        margin-top: 0;
        padding: 40px 0;
        .panel {
            &__size {
                margin-top: 0;
            }
        }
    }
}

@include for-big-screen {
    .avatar-container {
        max-height: unset;
        // width: 80%;
        .panel {
            zoom: 2;
        }
    }
}
