.webcam {
    position: relative;
    // max-height: fit-content;
    zoom: 1;

    video {
        transform: rotateY(180deg);
        -webkit-transform:rotateY(180deg); /* Safari and Chrome */
        -moz-transform:rotateY(180deg); /* Firefox */
        // zoom: 1.25;

    }



    &__loader {
        z-index: 500;
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }

    &__error {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        backdrop-filter: blur(6px);
        z-index: 200;
        background: #0000008c;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        
        
        
        &-text {
            padding: 10px 30px;
            border-radius: 20px;
            height: 200px;
            width: 50vw;
            background-color: black;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;


            div {
                height: 40px;
                width: 150px;
                background-color: white;
                color: black;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 20px;
                cursor: pointer;
                font-family:  Monserrat-bold;
            }
        }
    }


    &__flash {
        z-index: 40;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 40;
        // display: none;
        transition: all 0.3s linear;

        
        &--on {
            background-color: white;
            display: inline;
            transition: all 0.3s linear;
            z-index: 60;
        }
    }


    &__hint {
        position: absolute;
        color: white;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
        font-weight: bold;
        z-index: 51;
        text-shadow: 2px 2px #000000;
        font-size: 3vh;

    }

    &__perentesis {
        position: absolute;
        width: 30%;
        display: flex;
        justify-content: space-between;
        height: 15vh;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 50;


        &-left {
            transform: rotate(180deg);

        }
        &-right {}
    }


    .camera-overlay {
        position: relative;
        .overlay {
            position: absolute;
            opacity: 0.8;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            
            
        }
    
    }

    .camera-btn {
        background-color: white;
        display: inline-block;
        padding: 20px;
        border-radius: 100%;
        cursor: pointer;
        z-index: 50;
        opacity: 1;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 10vh;



    }


    &__preview {
        position: absolute;
        right: 20px;
        width: 20vw;
        height: 10vw;
        top: 10vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family:  Monserrat-bold;
        z-index: 110;


        &-btn {

            height: 40%;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2vh;
            cursor: pointer;
        }

        &-retake{
            background-color: white;
            color: black;

        }
        &-confirm{
            background-color: #f8686e;
            color: white;


            div {
                transform: rotate(-90deg);
            }
         
        }
    }
}



@media(min-width: 1601px){
    
    video {
        // zoom: 0.75;
    }
}