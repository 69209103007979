.scanner-iframe{
    height: 100vh;


    .logo {
        width: 181px;
        height: 36px;
        margin: 30px 0;
    }

    // iframe{
    //     width: 100vw;
    //     height: 270px;
    // }

    p{
        font-size: 16px;
        font-weight: 100;
        margin: 40px 0;
        color: #777;
    }

    img{
        // border-radius: 80px;
        // background-color: #f1686e;
        // color: #fff;
        // border: none;
        // width: 80%;
        // padding: 15px 0;
        // font-weight: 700;
        position: absolute;
        top: 10%;
        left: 13%;
        transform: translateX(-50%);
        transform: rotate(90deg);
        height: 3vh;
        cursor: pointer;
    }
}