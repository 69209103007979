@mixin for-phone-only {
    @media (max-width:600px), (max-height: 415px) {
        @content;
    }
}
@mixin for-phone-only-portrait {
    @media (max-width: 600px) and (orientation: portrait) {
        @content;
    }
}
@mixin for-phone-only-landscape {
    @media (max-height: 415px) and (orientation: landscape) {
        @content;
    }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 601px) and (max-width: 1200px) and (min-height: 780px) and (orientation: portrait) {
        @content;
    }
}
@mixin for-tablet-landscape-up {
    @media (min-height: 880px) and (max-height: 1400px) and (min-width: 781px) and (orientation: landscape) {
        @content;
    }
}
@mixin for-tablet-landscape2-up {
    @media (min-height: 400px) and (max-height: 880px) and (min-width: 781px) and (orientation: landscape) {
        @content;
    }
}
// @mixin for-laptop-up {
//     @media (min-width: 821px) {
//         @content;
//     }
// }
// @mixin for-desktop-up {
//     @media (min-width: 1025px) {
//         @content;
//     }
// }
@mixin for-big-desktop-up {
    @media (min-width: 1201px) {
        @content;
    }
}
@mixin for-big-screen {
    @media (min-width: 1601px) {
        @content;
    }
}

@mixin media($keys...) {
    @each $key in $keys {
      @if ($key == phone-portrait) {
        @include for-phone-only-portrait {
          @content
        }
      } @else if ($key == phone-landscape) {
        @include for-phone-only-landscape {
          @content
        }
      } @else if ($key == phone) {
        @include for-phone-only {
          @content
        }
      } @else if ($key == tablet-portrait) {
        @include for-tablet-portrait-up {
          @content
        }
      }@else if ($key == tablet-landscape) {
        @include for-tablet-landscape-up {
          @content
        }
      }@else if ($key == desktop) {
        @include for-laptop-up {
          @content
        }
      }
    }
  }


@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size: $min-font-size;
            @media screen and (min-width: $min-vw) {
                font-size: calc(
                    #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
                        ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
                );
            }
            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}