.item-list {
    display: flex;
    flex-direction: column;

    .order-total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        padding: 0px 28.5px 0px 20px;
        .title,
        .amount {
            font-family: "Monserrat-bold";
            font-size: 22px;
            font-weight: bold;
        }
        .amount {
        }
    }

    .no-items {
        color: white;
        font-weight: bolder;
        font-size: 19px;
        margin-block-end: 7px;
        // padding-inline-start: 32.5px;
        text-align: center;
        font-family: "Monserrat-medium";
        font-weight: 500;
    }
}
