.nav-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    backdrop-filter: blur(75px);
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    gap: 50px;
    padding-block-start: 150px;
    
    &.show{
        visibility: visible;
        opacity: 1;
    }

    h2 {
        font-size: 2rem;
        text-align: center;
        color: white;
        font-weight: 900;
        line-height: 1.4;
    }

    .buttons{
        display:flex;
        // gap: 50px;
        button{
            cursor: pointer;
            font-family: "Monserrat-bold", sans-serif;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 150px;
            border-radius: 60vh;
            height: 50px;
            background-color: $mainClr;
            border: none;
            color: white;
            &:hover{
                font-size: 20px;
            }
            &:last-of-type{
                margin-inline-start: 50px;
            }
        }
    }
}

@include media(phone-portrait) {
    .nav-msg{
        h2{
            font-size:1.3em;
        }
        .buttons{
            gap: 30px;
            button{
                width:100px;
                height: 40px;
                font-size: 1em;
            }
        }
    }
}