.slider-msg {
    width: 225px;
    height: 54px;
    border-radius: 27px;
    border-start-end-radius: 0!important;
    border-end-end-radius: 0!important;
    box-shadow: 0 0 20px 0 #000;
    background-color: #fff;
    display: flex;
    align-items: center;
    position: fixed;
    top: 102px;
    padding-inline-start: 3px;
    transition: 300ms;
    z-index: 200;
    &.hide{
        right: -230px;
    }
    &.show{
        right: 0;
    }

    .img{
        width: 48px;
        height: 48px;
        border-radius: 50%;
        object-fit: cover;
        margin-inline-end: 10px ;
    }
    
    .icon{
        max-width: 12px;
        max-height: 48px;
        object-fit: contain;
        margin-inline-end: 10px ;
    }

    .msg{
        font-family: "Monserrat-bold";
        font-size: 15px;
        font-weight: bold;
        color: #000;
        flex-grow: 1;
        text-align: left;
    }
}
