
.switch-small-circle .switch-container{
    display: flex;
    flex-direction: row;
    align-items: center;  
    justify-content: center;
    color: black;
    font-family: "Monserrat-bold", sans-serif;
    width: 160px;
    margin: auto;
    

  }

  .switch-small-circle .switch-button{     
    display: flex;
    justify-content: center;
    font-size: 14px;
    min-width: 40px;
    max-width: 40px;
    align-items: center;
    margin: 0;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    /* margin-right: 5px; */
    padding: 10px 10px;
    background: white;
    border-radius: 20px;
    margin: 10px 5px;
  }
  
  .switch-small-circle .switch-button__white{     
    display: flex;
    justify-content: center;
    font-size: 14px;
    align-items: center;
    width: 34px;
    height: 34px;
    margin: 0;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    /* margin-right: 5px; */
    padding: 10px 10px;
    color: black;
    border-radius: 30px;
    background-color: white;
    margin-right: 10px;
  }
  .switch-small-circle {
  
    .switch-button:hover{  
        transition: all .2s ease-out;
        color: #f1686e;
        border-radius: 30px;
        background-color: rgba(251,217,218,0.5);
        .active {
            color: white;
            border-radius: 30px;
            background-color: #f1686e;
        }
    }
  }
  .switch-small-circle .switch-button__white:hover{  
    color: #f1686e;
    border-radius: 30px;
    border: 1px #f1686e solid;
    transition: all 0.1s linear;
  }
  .switch-small-circle .active{  
    color: white;
    border-radius: 30px;
    background-color: #f1686e;
  }
  .switch-small-circle .active:hover{  
    color: white;
    border-radius: 30px;
    background-color: #f1686e;
  }
  .switch-small-circle .active__white, .switch-small-circle .active__white:hover{  
  
    border-radius: 30px;
    background-color: #f1686e;
    color: white;
    border: none;
  }
  
  
  
  