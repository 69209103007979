.step3 {
    display: grid;
    color: #fff;
    font-family: "Monserrat-bold";
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content min-content min-content;
    max-width: 1000px;
    max-height: 978px;
    width: min-content;

    &__tryon {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 500;
        background-color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5% 10%;
    }

    .top {
        .item-img {
            height: 449px;
            width: 449px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
            &.fade-in {
                animation: fadeIn 1500ms forwards;
            }
            &--try-on {
                object-position: top;
            }
        }
        // .item-img2 {
        //     position: absolute;
        //     transform: translatex(-100%);
        // }
    }

    .product-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-inline-start: 51px;
        font-family: "Monserrat", sans-serif;

        &__try-on {
            background-color: white;
            color: $mainClr;
            margin-top: 10px;
            cursor: pointer;
        }

        &__name {
            font-family: "Monserrat-bold", sans-serif;
            color: white;
            font-size: 23px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            max-height: 106px;
        }
        &__stock {
            font-size: 16px;
            margin-top: 11px;
            &.in-stock {
                color: $greenClr;
            }
            &.out-stock {
                color: $mainClr;
            }
        }
        &__price {
            font-size: 23px;
            font-weight: 600;
            color: white;
            margin-top: 13px;
        }

        &__button {
            cursor: pointer;
            font-family: "Monserrat-bold", sans-serif;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 250px;
            border-radius: 60vh;
            height: 50px;
            // & > div {
            //     max-height: -webkit-fill-available;
            // }
        }

        &__size {
            color: white;
            margin-top: 16px;
            cursor: default;
            div {
                width: 100%;
                text-align: left;
            }
            .product-info__user-name {
                font-family: "Monserrat", sans-serif;
                font-size: 17px;
            }
        }
        &__size-picker {
            margin-top: 20px;
            margin-bottom: 6px;
            position: relative;
            cursor: pointer;
            color: white;
            z-index: 2;

            &.disabled {
                //placeholder of disabled size
                & > div:first-of-type > div:first-of-type > div:first-of-type > div:first-of-type {
                    color: #cacaca;
                }
            }

            &.opened {
                z-index: 11;
                & > div > div:nth-of-type(2) {
                    //opened options container
                    background-color: #232323;
                    & > div:not(:first-of-type) {
                        //seperator lines
                        border-top: 0.5px solid;
                        border-image-slice: 1;
                        // border-width: 5px;
                        border-image-source: linear-gradient(
                            90deg,
                            rgba(255, 255, 255, 0) 0%,
                            rgba(255, 255, 255, 0.5) 10%,
                            rgba(255, 255, 255, 0.5) 90%,
                            rgba(255, 255, 255, 0) 100%
                        );
                    }
                }
                & > div > div:last-child > div > div {
                    color: #cacaca;
                    &._hiESnc { //selected item in list
                        background-color: $mainClr;
                        &>div{
                            color: white;
                        }
                        &.disabled {
                            //if item is not available
                            background-color: #eeeeee;
                            & > div {
                                //text of selected disabled item
                                color: #cacaca;
                            }
                            &:hover {
                                & > div {
                                    color: #7f7f7f;
                                }
                            }
                        }
                    }
                    &.disabled { //not selected items in list
                        &:hover {
                            //hover on unavailable item
                            background-color: #d2d2d2;
                            &>div{
                                color: #7f7f7f;
                            }
                        }
                    }
                    &:hover {
                        //hover on available item
                        background-color: #fcd9da;
                        & > div {
                            //text color of selected item when hover
                            color: $mainClr;
                        }
                    }
                    .disabled {
                        //text of disabled item
                        color: rgb(68, 68, 68);
                    }
                }
            }

            & > div {
                width: 100%;
                & > div:first-of-type {
                    width: 100%;
                    border-radius: 60vh;
                    min-height: 44px;
                    max-height: 44px;
                    background-color: #232323;
                    border: 2px solid white;
                    & > div:nth-of-type(1) div {
                        //placeholder text
                        color: white;
                    }
                    & > div:nth-of-type(2) {
                        //arrow down
                        right: 15px;
                        top: 50%;
                        transform: translateY(-40%);
                        & > div {
                            border-color: white;
                        }
                    }
                }
            }
        }
        &__add-to-basket {
            background-color: $mainClr;
            margin-top: 23px;
            transition: 0.3s;
            gap: 15px;
            &.invalid {
                background-color: rgb(159, 159, 159);
                cursor: not-allowed;
            }
            .cart-icon {
                height: 1.2em;
                position: absolute;
                transform: translateX(-450%);
            }
        }
        &__scan {
            cursor: default;
            margin-top: 41px;
            height: min-content;
            img {
                width: 25px;
                margin-right: 7px;
            }
            p {
                margin: 0;
            }
        }

        &__3d-btn {
            margin-top: 23px;
            background-color: #fff;
            color: $mainClr;
        }
        &__user-name {
            font-size: 16px;
            // font-weight: 600;
            color: white;
        }
    }

    .products-container {
        grid-column: span 2;
        margin-top: 15px;
        &__title {
            margin-left: 15px;
            // margin-bottom: 20px;
            font-size: 19px;
            font-weight: bold;
        }
        &__carousel {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            // width: 85vw;
            overflow-x: scroll;
            overflow-y: hidden;
            position: relative;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            // height: 100%;

            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                display: none;
            }

            .product-seperator {
                background-color: rgba(202, 202, 202, 1);
                width: 2px;
                min-width: 1px;
                height: 70px;
                padding-top: 20px;
            }

            .product {
                // width: 100%;
                max-width: 130px;
                &__image {
                    margin: auto;
                }
            }

            div:first-child .product {
                padding-inline-start: 0;
                width: 100%;
            }
            div:last-child .product {
                padding-inline-end: 0;
                width: 100%;
            }
            div:last-child div:first-child:not(.shimmer-loader) {
                width: min-content;
            }
        }
    }

    // h1 {
    //     width: 100%;
    //     // height: 130px;
    //     // margin: 53px 0 43px;
    //     font-family: Monserrat-bold;
    //     font-size: 4.28vmin;
    //     font-stretch: normal;
    //     font-style: normal;
    //     line-height: 1.38;
    //     letter-spacing: normal;
    //     text-align: center;
    //     color: #fff;
    //     margin: 0;
    //     span {
    //         text-transform: capitalize;
    //     }
    // }

    // .qr-code {
    //     position: relative;
    //     margin-bottom: 1.4vmin;
    //     img[alt="Barcode Icon"] {
    //         height: 6.25vmin;
    //         margin: 0;
    //     }
    //     span {
    //         width: 100%;
    //         position: absolute;
    //         display: block;
    //         animation-name: beam-laser;
    //         animation-duration: 0.9s;
    //         animation-timing-function: ease-in-out;
    //         animation-iteration-count: infinite;
    //         animation-direction: alternate;
    //         &::before {
    //             content: "";
    //             width: calc(100% + 5px);
    //             border: 2px solid $mainClr;
    //             position: absolute;
    //             top: -8px;
    //             left: -5px;
    //         }
    //     }
    // }

    // h2 {
    //     font-size: 4.28vmin;
    //     margin: 5px 0;
    // }
}



@include media(tablet-portrait) {

    .step3 {
        margin-top:50px;
        max-width: 700px;
        padding: 0 34px;
        zoom: 0.9;
        .top {
            width: 208px;
            .item-img {
                width: 208px;
                height: 208px;
            }
        }
        .carousel2 {
            // width: 208px;

            .slide{
                .color{
                    // background-color: #cacaca!important;
                    width: 35px !important;
                    height: 35px !important;
                    min-width: unset;
                    min-height: unset;

                }
            }
            .left-arrow, .right-arrow{
                // padding: 56px!important
            }



        }

        .color-cmp{
            width:230px
        }

        .product-info {
            padding-inline-start: 34px;
            &__name {
                font-size: 18px;
                // -webkit-line-clamp: 1;
            }
            &__stock {
                font-size: 16px;
            }
            &__price {
                margin-top: 12px;
                font-size: 20px;
            }
            &__size {
                margin-top: 13px;
                height: min-content !important;
            }
            &__3d-btn,
            &__size {
                height: 35px;
                min-height: unset;
                font-size: 13px;
            }
            &__size-picker {
                margin-top: 14px;
            }
            &__add-to-basket,
            &__3d-btn {
                margin-top: 20px;
            }
            &__scan {
                font-size: 12px;
                margin-top: 20px;
                height: 15px !important;
            }

            &__button {
                height: 44px;
                font-size: 13px;
            }
        }
        .products-container {
            max-height: min-content;
            min-height: min-content;
            margin-top: 23px;

            &__title {
                font-size: 19px;
                margin-bottom: 0;
                padding: 0;
                margin: 0;
                text-align: start;
            }
            &--similar {
            }
            .product {
                height: min-content;
                width: 130px;
                min-height: unset;
                padding: 10px 34px;
                &__name {
                    margin: 3px;
                    font-size: 14px;
                    overflow: hidden;
                    display: -webkit-box;
                    // -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2; /* number of lines to show */
                }
                &__price {
                    font-size: 16px;
                }
            }
        }
    }
}
@include media(tablet-landscape) {
    
    .step3 {
        
        // height: 100vh;
        zoom: 0.75;
        // max-height: 750px;
        max-width: 976px;
        padding-top: 70px;
        margin-bottom: 30px;
        // overflow-y: auto;
        
        .top {
            .item-img {
                height: 308px;
                width: 308px;
            }
        }
        .product-info {
            &__name {
                font-size: 18px;
            }
            &__stock {
                font-size: 16px;
            }
            &__price {
                font-size: 20px;
            }
            &__size {
                font-size: 17px;
                margin-top: 10px;
            }
            &__size-picker {
                margin-top: 10px;
            }
            &__add-to-basket,
            &__3d-btn {
                // margin-top: 10px;
            }
            &__button {
                height: 44px;
                font-size: 13px;
            }
            &__scan {
                font-size: 12px;
                height: min-content;
            }
        }
        .products-container {
            &__title {
                font-size: 19px;
            }
        }

        .product {
            &__name {
                font-size: 14px;
            }
        }
    }
}

@include media(phone-portrait) {
    .step3 {
        margin-top: 50px;
        max-width: 90vw;
        grid-template-columns: 100%;
        grid-template-rows: min-content min-content min-content min-content;
        overflow-y: auto;
        .top {
            justify-self: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            .item-img {
                width: 15vh;
                height: 15vh;
            }
        }
        .product-info {
            justify-self: center;
            align-items: center;
            padding: 0;
            margin-bottom: 32px;
            &__name {
                font-size: 15px;
                text-align: center;
            }
            &__stock {
                // font-size: 16px;
            }
            &__price {
                font-size: 20px;
                margin-top: 8px;
            }
            &__size {
                font-size: 16px;
                margin-top: 6px;
            }
            &__size-picker {
                margin-top: 14px;
                font-size: 15px;
            }
            &__add-to-basket {
                margin-top: 12px;
                div {
                    font-size: 15px;
                }
            }
            &__button {
                height: 45px;
                div {

                    text-align: center;
                }
            }
            &__scan {
                font-size: 12px;
                height: 15px;
                margin-top: 27px;
            }
        }
        .products-container {
            &:not(&--similar) {
                margin-top: 25px;
            }
            grid-column: 1;
            &__title {
                font-size: 13px;
                text-align: center;
                // margin-bottom: 12px;
                margin: 0;
            }
            &__carousel {
                .product {
                    height: 135px;
                    max-width: 95px;
                    min-width: 95px;
                    &__image {
                        img {
                            height: 45px;
                            width: 45px;
                        }
                    }
                    &__name,
                    &__price {
                        font-size: 11px;
                    }
                    &__name {
                        max-height: 2.7em;
                        min-height: 2.7em;
                    }
                }
            }
        }
    }
}

@include media(phone-landscape) {
    .step3 {

        
        grid-template-columns: 50% 50%;
        grid-template-rows: 40% 60%;
        gap: 0;
        .top {
            grid-column: 1;
            grid-row: 1;
            margin: auto;
            .item-img {
                width: 30vh;
                height: 30vh;
            }
        }
        .product-info {
            grid-column: 1;
            grid-row: 2;
            padding: 0;
            justify-self: center;
            align-items: center;
            gap: 2px;
            &__name {
            }
            &__stock {
                margin: 2px 0;
            }
            &__size {
                font-size: 3vmin;
            }
            &__button {
                margin: 0;
            }
            &__size-picker {
                font-size: 3vmin;
                margin-bottom: 5px;
                height: 25px;
            }
            &__add-to-basket {
                min-height: 4vmin;
                font-size: 3vmin;
            }
            &__scan {
                margin-top: 10px;
                & > img {
                    width: 15px;
                }
            }
        }
        .products-container {
            grid-column: 2;
            grid-row: 1;
            width: 100%;
            padding: 0 5px;
            position: relative;
            background-color: #000;
            margin: 0;
            &--similar {
                grid-row: 2;
                margin-top: 10px;
                align-self: center;
            }
            &::before,
            &::after {
                content: "";
                height: 100%;
                width: 0px;
                box-shadow: 0 0 10px 1.5px #fff;
                position: absolute;
                top: 0;
                z-index: -1;
            }
            &::before {
                left: 0;
            }
            &::after {
                right: 0;
            }
        }
    }
}

@include for-big-screen {
    .step3 {
        zoom: 1;
    }
}

@include for-tablet-landscape2-up {
    .step3 {
        width: min-content;
        // background-color: red;
        zoom: 0.5;
    }
}

