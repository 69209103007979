.step2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 70vmin);
    align-self: start;
    gap: 2vh;

    // margin-top: 150px;

    .user {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 1.5vh;

        // width: 100%;
        // height: 6.5rem;
        // margin: auto;
        // max-width: 80%;
        padding: 2vmin 7vmin;
        border-radius: 40vmin;
        background-color: rgba(0, 0, 0, 0.37);
        h1 {
            margin: 0;
            font-family: "Monserrat-bold";
            font-size: 6.2vmin;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            text-transform: capitalize;
            word-break: break-word;
        }
        img {
            height: 6.6875vmin;
            width: 6.6875vmin;
        }
    }

    .scan {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        transform: translateY(10px);
        img {
            margin: 2vh 0;
            height: 10vmin;
            max-width: 23vw;
        }
        span {
            width: 100%;
            // left: -6%;
            position: absolute;
            display: block;
            animation-name: beam;
            animation-duration: 0.9s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            &::before {
                content: "";
                width: calc(100% + 5px);
                border: 2px solid $mainClr;
                position: absolute;
                top: 3px;
                left: -5px;
            }
        }
    }

    h3 {
        font-family: "Monserrat-bold";
        font-size: 4.28vmin;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        margin: 0;
        // margin-bottom: 50px;
        span {
            text-transform: capitalize;
        }
    }
}

@keyframes beam {
    from {
        top: 2vh;
    }
    to {
        top: calc(100% - 2.5vh);
    }
}
