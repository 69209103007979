.shopping-msg {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    isolation: isolate;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;

    .img {
        max-width: 100px;
        max-height: 100px;
        margin-block-start: 93px;
        margin-block-end: 34px;
    }

    .msg {
        font-family: "Monserrat-bold";
        font-weight: bold;
        font-size: 4vw;
        color: #fff;
        max-width: 80%;
        margin: 0;
        white-space: pre;
        text-align: center;
        i{
            font-family: "Monserrat";
            font-weight: normal;
            font-style: normal;
            font-size: 33px;
            white-space: break-spaces;
        }
    }
    .btn {
        all: unset;
        margin: 0 auto;
        width: 218px;
        min-height: 46px;
        border-radius: 23.5px;
        background-color: $mainClr;
        color: #fff;
        font-family: "Monserrat-bold";
        font-size: 15px;
        text-align: center;
        cursor: pointer;
        margin-block-start: 48px;
    }

    .pie-timer{
        align-self: flex-end;
        .wrapper{
            background: #000;
        }
    }
}
