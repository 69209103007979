.checkout {
    isolation: isolate;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    zoom: unset;
    // padding-inline: 160px;
    .scroll {
        overflow-y: auto;
        position: relative;
        // top: 160px;
        width: 60vw;
        margin: auto;

        max-height: calc(100% - 60px );

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        // top: 50%;

        // transform: translateY(-50%);

        // padding-inline-end: 40px;
        // padding-inline-start: 40px;

        &::-webkit-scrollbar {
            width: 0.3em;
            height: 0.1em;
        }
        &::-webkit-scrollbar-button {
            background: transparent;
        }
        &::-webkit-scrollbar-track-piece {
            background: #262626;
        }
        &::-webkit-scrollbar-thumb {
            background: rgb(71, 71, 71);
        }

        nav {
            padding: 0;
            display: flex;
            align-items: flex-end;
            position: relative;

            .back {
                position: absolute;
                left: 0;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    height: 30px;
                }
            }
            h1 {
                width: 100%;
                color: #fff;
                font-size: 25px;
                font-family: "Monserrat-bold";
                font-weight: bold;
                text-align: center;
                margin: 0;
            }
        }
        details {
            color: white;
            width: 100%;

            summary {
                list-style: none;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &::-webkit-details-marker {
                    display: none;
                }
                label {
                    font-family: "Monserrat-bold";
                    font-size: 22px;
                }
                span {
                    position: relative;
                    padding-inline-end: 30px;
                    font-size: 22px;
                    font-family: "Monserrat-medium";

                    &::after {
                        content: "";
                        background: url("../../../img/back-arrow.svg") 0 0 no-repeat;
                        width: 20px;
                        height: 30px;
                        display: inline-block;
                        position: absolute;
                        top: -1px;
                        right: 2px;
                        transform: rotate(-90deg) scale(0.5);
                    }
                }
            }
            &[open] {
                summary {
                    span {
                        &::after {
                            transform: rotate(90deg) scale(0.5);
                        }
                    }
                }
            }
            .item-list{
                padding-top: 44px;
            }
        }
        .state {
            margin-top: 36px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                width: 83.1px;
                height: 63.7px;
            }
            h1 {
                margin-block-start: 29px;
                margin-block-end: 0;
                font-size: 25px;
                color: #fff;
                font-family: "Monserrat-bold";
                font-weight: bold;
                text-align: center;
            }
        }
        footer {
            margin-block-start: 45px;
            display: flex;
            justify-content: center;
            width: 100%;
            .btn {
                all: unset;
                width: 218px;
                min-height: 47px;
                border-radius: 23.5px;
                background-color: $mainClr;
                color: #fff;
                font-family: "Monserrat-bold";
                font-size: 15px;
                text-align: center;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}



@include media(phone-portrait){

    .shopping-bag {

        .container {
            width: 100vw;
            padding-inline-end: unset;
        }
    }

    .checkout {
        padding: 0 30px 40px;
        .scroll {
            width: 100%;

            .state {

                h1 {
                    font-size: 20px;
                }
            }
        }
    }
    .back{
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            height: 25px;
        }
    }
     
}