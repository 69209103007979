.carousel2 {
    $selectedBorder: 5px;
    $radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .left-arrow{
        margin-right: auto;
        transform: rotate(180deg);
    }
    .left-arrow, .right-arrow{
        display: none;
        img{
            width:10px;
            height: 20px;
        }
        &.show{
            display: block;
            cursor: pointer;
            padding: 15px;
        }
    }
    &.static{
        gap: 50px;
        .slide{
            transform: unset !important;
            top: unset !important;
            left: unset !important;
            right: unset !important;
            position: unset !important;
        }
    }
    .slide {
        position: absolute;
        top: 50%;
        left: 50%;
        transition: 0.5s;
        .color {
            border: 1px solid #fff;
            box-shadow: inset 0 0 1px 1px #000;
            border-radius: 50%;
            background-color: var(--color);
            width: $radius !important;
            min-width: $radius;
            height: $radius !important;
            min-height: $radius;
            cursor: pointer;
            transition: 0.3s;
        }
        &.hidden {
            // visibility: hidden;
            // width: 0;
            // height: 0;
            transform: translate(-55%, -50%) scale(0);
            .color {
                position: relative;
            }
        }
        &.before {
            transform: translate(calc(-150% - 20px), -50%);
            &.selected{
                // transform: translateX(calc(-150% - 6px));
                left: 50%;
            }
            z-index: 2;
        }
        &.center {
            position: absolute;
            z-index: 3;
            transform: translate(-50%, -50%);
        }
        &.after {
            transform: translate(calc(150% - 3px), -50%);
            &.selected{
                // transform: translateX(calc(150% - 34px));
                // left: calc(50% - 50px);
            }
            left: unset;
            z-index: 2;
        }
        &.selected {
            .color {
                border: $selectedBorder solid #fff;
            }
        }
    }
}



@media (max-width: 600px) {
    .color-cmp {
        height: 50px !important;

        .carousel2 {
            // background-color: red;
            .slide.selected {
                .color{

                    border: 3px solid #fff;
                }
            }

            .color {
                width: 30px !important;
                min-width: 30px !important;
                height: 30px !important;
                min-height: 30px !important;
                }
            
        } 
    }  
}